import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import lodash from 'lodash'
import {
  Box,
  Button,
  Card,
  InputLabel,
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  Select,
  SvgIcon,
  withStyles,
  MenuItem
} from '@material-ui/core'
import { Search as SearchIcon } from 'react-feather'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import {projectArrayPropType, sessionArrayPropType} from '../../../utils/propTypesUtils'
import PiCardContent from '../../../components/PiCardContent'

const withStyle = withStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}))

const getProjItemLabel = (projectName, sessionCount) => {
  return `${projectName} (${sessionCount})`
}

const getAccItemLabel = (accName, sessionCount) => {
  return `${accName} (${sessionCount})`
}

class SessionListToolbar extends Component {

  constructor (props) {
    super(props)
    this.state = {
      searchingText: '', // the text about to search
      selectedProjectId: '',
      selectedAccId: '',
    }
  }

  setSearchingText = (searchingText) => {
    const newState = lodash.merge(this.state, {
      searchingText
    })
    this.setState(newState)
  }

  setSelectedProjectId = (selectedProjectId) => {
    this.setState({
      selectedProjectId
    })
  }

  setSelectedAccId = (selectedAccId) => {
    this.setState({
      selectedAccId
    })
  }

  render() {
    const { projects, setSessionSearchText, setFilteredProjectId, setFilteredAccId, onAddSession, className, classes, sessionList, ...rest } = this.props
    const { searchingText, selectedProjectId, selectedAccId } = this.state

    const projectIdToSessionCount = {}
    const sessionTotCount = lodash.size(sessionList)
    lodash.forEach(sessionList, ((s) => {
      const { projectId } = s
      const c = lodash.get(projectIdToSessionCount, projectId, 0)
      projectIdToSessionCount[projectId] = c + 1
    }))

    const projectOptions = lodash.map(projects, (p) => {
      const { id, name } = p
      const c = lodash.get(projectIdToSessionCount, id, 0)
      return {
        value: id,
        label: getProjItemLabel(name, c)
      }
    })

    const accIdToSessionCount = {}
    let filteredSessTotCount = 0
    let allAccounts = []
    lodash.forEach(sessionList, (sess = {}) => {
      const { projectId } = sess
      if (selectedProjectId !== undefined && selectedProjectId !== '') {
        if (projectId !== selectedProjectId) {
          return
        }
      }

      filteredSessTotCount = filteredSessTotCount + 1
      const accts = lodash.get(sess, ['data', 'accounts'], [])
      const accIdAddedForSession = {}
      lodash.forEach(accts, (acc) => {
        const { accId, accName }= acc
        const added = lodash.get(accIdAddedForSession, accId, false)
        if (added) {
          return
        }
        accIdAddedForSession[accId] = true
        const ct = lodash.get(accIdToSessionCount, accId, 0)
        accIdToSessionCount[accId] = ct + 1
        allAccounts.push({
          accId, accName
        })
      })
    })

    allAccounts = lodash.uniqBy(allAccounts, ({ accId }) => {
      return accId
    })

    const accountOptions = lodash.map(allAccounts, ({ accId, accName }) => {
      const c = lodash.get(accIdToSessionCount, accId, 0)
      return {
        value: accId,
        label: getAccItemLabel(accName, c)
      }
    })

    const onSearch = (event) => {
      const val = lodash.get(event, ['target', 'value'], '')
      const newSearchText = String(val)
      this.setSearchingText(newSearchText)
      setTimeout(() => {
        if (this.state.searchingText === newSearchText) {
          setSessionSearchText(newSearchText)
        }
      }, 500)
    }

    const onProjectChange = (event) => {
      const val = event.target.value
      this.setSelectedProjectId(val)
      setFilteredProjectId(val)

      // reset the account id when project is updated
      this.setSelectedAccId('')
      setFilteredAccId('')
    }

    const onAccChange = (event) => {
      const val = event.target.value
      this.setSelectedAccId(val)
      setFilteredAccId(val)
    }

    return (
      <div
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onAddSession}
          >
            Add Session
          </Button>
        </Box>
        <Box mt={3}>
          <Card>
            <PiCardContent
              disablePiStyle
            >
              <Grid display="flex" container spacing={3}>
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    value={searchingText}
                    onChange={onSearch}
                    placeholder="Search session"
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">Filter by Project</InputLabel>
                    <Select
                      value={selectedProjectId}
                      onChange={onProjectChange}
                    >
                      <MenuItem key="empty_item" value="">{getProjItemLabel('All', sessionTotCount)}</MenuItem>
                      {
                        projectOptions.map((op, idx) => {
                          return (
                            <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">Filter by Account</InputLabel>
                    <Select
                      value={selectedAccId}
                      onChange={onAccChange}
                    >
                      <MenuItem key="empty_item" value="">{getAccItemLabel('All', filteredSessTotCount)}</MenuItem>
                      {
                        accountOptions.map((op, idx) => {
                          return (
                            <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </PiCardContent>
          </Card>
        </Box>
      </div>
    )
  }
}

SessionListToolbar.propTypes = {
  className: PropTypes.string,
  sessionList: sessionArrayPropType,
  setSessionSearchText: PropTypes.func.isRequired,
  setFilteredProjectId: PropTypes.func.isRequired,
  setFilteredAccId: PropTypes.func.isRequired,
  onAddSession: PropTypes.func.isRequired,
  projects: projectArrayPropType,
}

SessionListToolbar.defaultProps = {
  projects: [],
  sessionList: [],
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  const sessionList = lodash.get(state, ['single', 'curPageData', 'sessionList'], [])
  return {
    projects,
    sessionList
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(SessionListToolbar))
