import React from 'react'
import {makeStyles, TableBody, TableRow, Typography} from '@material-ui/core'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import {
  CMD_TOKEN_BUY_SELL_EXECUTE,
  CMD_TOKEN_BUY_SELL_STOP,
  SIDE_BUY
} from '../../../../protocolConstants'
import ColorButton from '../../../../components/ColorButton'
import PropTypes from 'prop-types'
import { tokenBuySellDialogPropType } from '../../../../utils/propTypesUtils'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import { SIDE_SELL } from '../../../../protocolConstants'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'

const useStyles = makeStyles((theme) => ({
  lineThrough: {
    'text-decoration': 'line-through',
  },
}))

const TableRowNameVal = ({name, value, isLineThrough = false}) => {
  const classes = useStyles()
  let className = ''
  if (isLineThrough) {
    className = classes.lineThrough
  }
  return (
    <TableRow hover>
      <PiTableCell
        variant='head'
      >
        <Typography className={className}>
          {name}
        </Typography>
      </PiTableCell>
      <PiTableCell>
        <Typography className={className}>
          {value}
        </Typography>
      </PiTableCell>
    </TableRow>
  )
}
const DialogContent = ({previewData}) => {
  const { buySell, itvlMs, remainingQty, remainingAsset, targetQty, targetQtyEnabled, targetAsset, layerNum, layerGap, orderQty, orderLmtPx, minSpread, tokenBuySellMode } = previewData
  return (
    <XscrollTable>
      <TableBody>
        <TableRowNameVal
          name="Side"
          value={buySell}
        />
        <TableRowNameVal
          name="Interval (sec)"
          value={parseInt(itvlMs / 1000)}
        />
        <TableRowNameVal
          name="Remaining Asset"
          value={`${remainingQty} (${remainingAsset})`}
        />

        <TableRowNameVal
          isLineThrough={!targetQtyEnabled}
          name="Target Asset"
          value={`${targetQty} (${targetAsset})`}
        />

        <TableRowNameVal
          name="Target Asset Enabled"
          value={targetQtyEnabled.toString()}
        />

        <TableRowNameVal
          name="Layer Num"
          value={layerNum}
        />

        <TableRowNameVal
          name="Layer Gap"
          value={layerGap}
        />

        <TableRowNameVal
          name="Order Qty"
          value={orderQty}
        />
        <TableRowNameVal
          name="Order Lmt Px"
          value={orderLmtPx}
        />
        <TableRowNameVal
          name="Min Spread"
          value={minSpread}
        />
        <TableRowNameVal
          name="Mode"
          value={tokenBuySellMode}
        />
      </TableBody>
    </XscrollTable>
  )
}


const TokenBuySellAlgoFormDialog = ({dialogData, setCurPageData, sendReq, sessionId}) => {
  if (!dialogData) {
    return null
  }
  const { dialogOpen, dialogType, previewData, previewSentData} = dialogData
  const hideDialog = () => {
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen: false,
      }
    })
  }

  if (dialogType === 'executeTokenBuySellAlgo') {
    const executeTaker = () => {
      sendReq({
        cmd: CMD_TOKEN_BUY_SELL_EXECUTE,
        isRelay: true,
        data: lodash.get(previewSentData, 'data')
      })
      hideDialog()
    }
    const { buySell } = previewData
    let confirmButton
    if (buySell === SIDE_BUY) {
      confirmButton = <ColorButton onClick={executeTaker} colorStyle='green'>
        Execute Buy Token
      </ColorButton>
    } else if (buySell === SIDE_SELL) {
      confirmButton = <ColorButton onClick={executeTaker} colorStyle='red'>
        Execute Sell Token
      </ColorButton>
    } else {
      // something wrong, hide the button
      confirmButton = null
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to execute token buy sell algo?'
        dialogContent={<DialogContent previewData={previewData}/>}
        dialogCancelText='Dismiss'
        dialogConfirmButton={confirmButton}
        dialogOnCancel={hideDialog}
      />
    )
  } else if (dialogType === 'stopTokenBuySellAlgo') {
    const stopTaker = () => {
      sendReq({
        cmd: CMD_TOKEN_BUY_SELL_STOP,
        isRelay: true,
        data: {
          sessionId
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to stop Token Buy Sell algo?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Stop now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={stopTaker}
      />
    )
  } else {
    return null
  }
}


TokenBuySellAlgoFormDialog.propTypes = {
  dialogData: tokenBuySellDialogPropType,
  setCurPageData: PropTypes.func.isRequired,
  sendReq: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired
}

TokenBuySellAlgoFormDialog.defaultProps= {

}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  return {
    dialogData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenBuySellAlgoFormDialog)
